import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { ContactForm } from "@/components/contact-form";
import { Arrow } from "@/components/arrow";
import { GatsbySeo, JsonLd } from "gatsby-plugin-next-seo";
import { Layout } from "@/components/layout";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";

const RedAlert = () => {
  return (
    <>
      <GatsbySeo
        title="Red Alert - Services | Tectra Technologies"
        canonical="https://www.tectratechnologies.com/services/red-alert/"
        openGraph={{
          locale: "en_US",
          type: "article",
          url: "https://www.tectratechnologies.com/services/red-alert/",
          title: "Red Alert - Services | Tectra Technologies",
        }}
        twitter={{
          handle: "@handle",
          site: "@TectraL",
          cardType: "summary_large_image",
        }}
      />
      <JsonLd
        json={{
          "@context": "https://schema.org",
          "@type": "WebSite",
          "@graph": [
            {
              "@type": "WebSite",
              "@id": "https://www.tectratechnologies.com/#website",
              url: "https://www.tectratechnologies.com/",
              name: "Tectra Technologies",
              description:
                "Web &amp; Mobile App, Cloud, UI/UX, Devops, Tax Services, Digital Marketing",
              potentialAction: [
                {
                  "@type": "SearchAction",
                  target: {
                    "@type": "EntryPoint",
                    urlTemplate:
                      "https://www.tectratechnologies.com/?s={search_term_string}",
                  },
                  "query-input": "required name=search_term_string",
                },
              ],
              inLanguage: "en-US",
            },
          ],
        }}
      />
      <Layout>
        <div className="bg-white h-screen flex items-center justify-center">
          <div className="w-full max-w-7xl mx-auto px-5 lg:px-8">
            <div className="text-left xl:w-3/5">
              <div className="">
                <h1 className="text-black text-4xl font-poppins font-semibold mb-4 normal-case leading-relaxed">
                  Excellent in healthcare &amp; service.
                </h1>
                <div className="relative flex overflow-hidden justify-start items-center text-center">
                  <Link
                    to="/contact"
                    className="flex items-center text-xl normal-case hover:text-black"
                    activeClassName="border-b-2 border-black"
                  >
                    Talk to us
                    <Arrow color="black" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-black relative overflow-hidden mt-20 lg:mt-0">
          <div className="max-w-7xl mx-auto px-0 lg:px-8 relative">
            <div className="grid grid-cols-1 items-center justify-center lg:gap-y-2 lg:grid-cols-12">
              <div className="flex flex-wrap items-center justify-center px-4 lg:px-0 lg:pr-12 sm:mx-0 lg:col-span-6">
                <div className="relative z-10 gap-x-4 lg:px-0 my-8 lg:my-0 lg:mx-0 lg:block lg:gap-x-0 lg:gap-y-1">
                  <div className="text-white">
                    <h1 className="text-lg font-poppins font-medium text-white capitalize">
                      Client:
                    </h1>
                    <p className="text-base font-poppins font-thin">
                      Red alert online sdn bhd
                    </p>
                  </div>
                  <div className="text-white mt-4">
                    <h1 className="text-lg font-poppins font-medium text-white capitalize">
                      Tools &amp; Technologies
                    </h1>
                    <p className="text-base font-poppins font-thin">
                      Mobile App: React native
                    </p>
                  </div>
                  <div className="text-white mt-4">
                    <h1 className="text-lg font-poppins font-medium text-white capitalize">
                      Product Features:
                    </h1>
                    <ul className="text-base font-poppins font-thin text-white ml-10 list-disc leading-8">
                      <li>Automated Claims preparing</li>
                      <li>Facilitates with QR codes via smartphones</li>
                      <li>Streamline routes to claim History</li>
                      <li>Mechanized updates on Claim status</li>
                      <li>Bulk Claim Uploads</li>
                      <li>Locate the chosen panel clinics through the Maps</li>
                      <li>Hassle-free Reimbursements.</li>
                      <li>Customized options for all the requirements</li>
                      <li>Seamless user experience &amp; Accounts managing</li>
                      <li>Automated view on the status of Medical expenses.</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="lg:col-span-6">
                <div className="relative w-auto overflow-hidden lg:mt-0 lg:w-[67.8125rem]">
                  <StaticImage
                    src="../../images/national-cancer-institute-NFvdKIhxYlU-unsplash1.png"
                    alt=""
                    layout="constrained"
                    placeholder="blurred"
                    quality={95}
                    height={700}
                    className="w-full h-60 md:h-92 lg:h-auto"
                    formats={["auto", "png"]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white mt-10 lg:mt-12">
          <div className="max-w-7xl mx-auto px-5 lg:px-8">
            <h2 className="text-black text-left text-2xl font-poppins font-semibold captalize tracking-tight">
              Glipmse Of Our Work
            </h2>
            <div className="mt-6 lg:mt-12">
              <Swiper
                slidesPerView={1}
                spaceBetween={20}
                navigation={true}
                modules={[Navigation]}
                breakpoints={{
                  360: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                }}
              >
                <SwiperSlide>
                  <StaticImage
                    src="../../images/Frame48.png"
                    alt=""
                    layout="constrained"
                    placeholder="blurred"
                    quality={95}
                    height={850}
                    className="w-full h-60 md:h-92 lg:h-auto"
                    formats={["auto", "png"]}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <StaticImage
                    src="../../images/sdfsfshkh342bjhr.png"
                    alt=""
                    layout="constrained"
                    placeholder="blurred"
                    quality={95}
                    height={850}
                    className="w-full h-60 md:h-92 lg:h-auto"
                    formats={["auto", "png"]}
                  />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default RedAlert;
